const bios = [
  {
    id: 1,
    firstName: 'Edward',
    lastName: 'Urquhart',
    title: 'Chief Executive Officer',
    mobile: '2036446458',
    email: 'eau@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio: 'Ed started his career at McQuilling Brokerage Partners Inc. in the Clean Department. After 3 years at Mcquilling, he joined MJLF and Associates where he managed the Clean Department. He graduated from Fordham University in 2000 with a degree in Finance.',
  },
  {
    id: 2,
    firstName: 'Frank',
    lastName: 'Rendon',
    mobile: '2036446269',
    email: 'fjr@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio: 'After several years in the Finance industry, Frank spent four years working tanker markets for clean petroleum products, primarily in South America. Frank is bilingual (Spanish) and holds a BS in Marketing from Susquehanna University.',
  },
  {
    id: 3,
    firstName: 'William',
    lastName: 'Hirschfeld',
    mobile: '2038072126',
    email: 'wjh@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio: 'Bill joined True North Chartering in 2010 after graduating from Fairfield University with a BA in Economics. He has spent most of this time with the company working as part of the Clean desk where he currently sits with a focus in the LR West market.',
  },
  {
    id: 5,
    firstName: 'Brian',
    lastName: 'Boorse',
    mobile: '6103932872',
    email: 'brb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio: 'Brian joined True North Chartering in 2015 as a tanker broker concentrating on the clean petroleum markets. Prior to True North, he worked in sales for a wealth management firm and graduated from Ursinus College with a BA in Business and Economics.',
  },
  {
    id: 6,
    firstName: 'Sean',
    lastName: 'Peterkin',
    mobile: '2039456901',
    email: 'spn@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio: 'Sean graduated from SUNY Maritime in 2003 with a degree in Business Administration and Marine Transportation. Shortly after graduating, Sean began his career as a spot broker working in both CT and GVA for MJLF, as well as ICAP in London. Sean joined True North in 2012 where he continues to focus on the dirty spot market.',
  },
  {
    id: 7,
    firstName: 'Brian',
    lastName: 'Vogdes',
    mobile: '2032199751',
    email: 'bjv@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio: 'Brian Vogdes is presently working Crude/DPP markets. His primary focus are Crude markets in the Americas and West Africa. Brian has been with True North Chartering since it opened in 2009. Prior to chartering, he worked for World Fuel Services brokering and trading bunkers in the Caribbean, and sailed coastwise aboard a US Flagged tanker. Brian serves as a Director of ASBA and graduated from SUNY Maritime with a B.S. in Marine Transportation in 2004.',
  },
  {
    id: 8,
    firstName: 'Drew',
    lastName: 'Balshi',
    mobile: '2034005200',
    email: 'acb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio: 'Drew Balshi graduated Lehigh University in 2009 with a Bachelor’s degree in Business Management. His shipping career began as a trainee in 2007 at MJLF. He has worked in a variety of dirty sectors with True North since it’s opening year in 2009. Drew is the head of the DPP Desk at True North, covering all sectors MRs through VLCCs. He enjoys playing basketball and golf as well as exploring the endless new restaurants of New York City.',
  },
  {
    id: 9,
    firstName: 'Dwight',
    lastName: 'Tompkins',
    mobile: '9142621628',
    email: 'djt@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio: 'Dwight graduated from SUNY Maritime in 2007. After graduation he sailed on US Flag ATBs as Second Mate. In 2009, he transitioned to an Operations role at General Maritime. He later moved to Chartering Manager, covering Panamax, Aframax and Suezmax until joining True North in June 2018.',
  },
  {
    id: 10,
    firstName: 'Peter',
    lastName: 'Klopfer',
    mobile: '5165894525',
    email: 'pmk@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio: 'In 2021, Peter M. Klopfer joined True North Chartering LLC, where he heads up the S&P department. Prior to this role, Mr. Klopfer had a highly successful and impactful career in the international shipping industry, directly and indirectly involved in countless new-building and second-hand vessel transactions. Previously serving as the President of P. M. Klopfer Shipbrokers, Inc., he provided ship brokerage, maritime consulting, and financial/securities analysis services to the shipping and financial communities. In addition, he has led significant transactions, such as the sale of Burmah Castrols ULCC fleet to Stena Line Concordia A/B and the co-founding of First Ship Lease, a commercial finance company focusing on diverse maritime assets. His diverse expertise and achievements have cemented his influential and respected position within the industry.',
  },
  {
    id: 11,
    firstName: 'Jerry',
    lastName: 'Lichtblau',
    mobile: '2038070699',
    email: 'jnl@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio: 'Jerry heads our Industry Research and Analysis efforts, has over 25 years of Maritime Industry experience. Prior to joining True North he was in charge of all research and financial analysis at MJLF & Associates for 13 years. He also spent 7 plus years with OSG where he was an officer in the finance department. He has also worked for Sea-Land Corporation as well in the financial industry. He has a BS from SUNY Maritime College in Meteorology and Oceanography and an MBA from Columbia University in Finance.',
  },
  {
    id: 13,
    firstName: 'Kaird',
    lastName: 'Durocher',
    mobile: '2034176690',
    email: 'kdr@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio: 'Kaird graduated from Hartwick University in 2012 with a BS in Business Administration and joined the True North brokering team concentrating on the clean sector. After a hand full of years he now joins Keith, Noel and Jerry on the Projects Department. Prior to working full time at True North, Kaird spent summers interning at True North, as well as at the legal department of Victaulic, the world leader in mechanical pipe joining systems.',
  },
  {
    id: 15,
    firstName: 'Christian',
    lastName: 'Uecker',
    mobile: '2034346162',
    email: 'cdu@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio: 'Christian, head of our operations staff, brings experience as a Tanker Agent and Operations Manager at former OMI Corp and Torm USA. Christian brings extensive operations experience with both large tankers and product carriers. He is a graduate of SUNY Maritime College with a BS degree in Maritime Transportation.',
  },
  {
    id: 16,
    firstName: 'Edward',
    lastName: 'Micola',
    mobile: '2039215116',
    email: 'evm@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio: 'Prior to brokering, Ed spent five years as an Officer of an ocean going tanker. During the course of the last four years, he has developed expertise in Suezmax and Aframax tanker markets. He is a graduate of SUNY Maritime with a degree in Maritime Transportation.',
  },
  {
    id: 17,
    firstName: 'Edwin',
    lastName: 'Arias',
    mobile: '9148796886',
    email: 'exa@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio: 'Edwin is a part of the operations team, previously a clean broker, and has been with the company since 2009. He is a graduate of Towson University in Maryland with a BS in Marketing.',
  },
  {
    id: 18,
    firstName: 'Eric',
    lastName: 'Halbeck',
    mobile: '2039124118',
    email: 'erh@truenorthchartering.com',
    emailGroup: 'claims@truenorthchartering.com',
    desk: 'claims',
    bio: 'After graduating from SUNY Maritime, Eric embarked on an eighteen year, world wide, sailing career on tankers, with the last eight years as Master. He was then asked to come ashore and conduct the operations for OMI\'s suezmax vessels in New York. While at OMI. he was transferred to a subsidiary of OMI, International Product Carriers, a vessel pooling arrangement .  The pool dissolved when a majority of the vessels were sold. He was then hired by MJLF to conduct broker operations and promoted a few years later to the Ops Manger position.  After seventeen years at MJLF he decided to retire early and pursue his hobbies of fishing, cooking and travel. After a few years, he decided to reenter the shipping world and signed on with True North Chartering in August 2024.',
  },
  {
    id: 19,
    firstName: 'Mike',
    lastName: 'Hyland',
    mobile: '7328225697',
    email: 'moh@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio: 'Mike joined True North in January of 2022 and is working in the Crude/DPP markets. Prior to joining True North Mike spent 15 years with the LITASCO group in the role of Senior Freight Trader between NY and Geneva. While with the Litasco Group Mike chartered vessels for the CPP, DPP and Crude desks and negotiated and traded time charters for Eiger Shipping S.A.. Before joining Litasco in 2007 Mike worked for 5 years as a broker at MJLF in the spot department. He is a graduate of the U.S. Merchant Marine Academy. Mike enjoys fixing ships and golf.',
  },
  {
    id: 20,
    firstName: 'Bobby',
    lastName: 'Beane',
    mobile: '7814240007',
    email: 'rcb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio: 'Bobby graduated from Massachusetts Maritime in 2019 where he played baseball and earned a degree in International Maritime Business. Prior to joining the DPP desk at True North he spent years working everyday on the water at a local marina. He enjoys Led Zeppelin, watching his hometown Boston sports teams and playing golf.',
  },
  {
    id: 21,
    firstName: 'Lenny',
    lastName: 'Joutovsky',
    mobile: '5165894525',
    email: 'lmj@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio: 'Lenny joined True North Chartering in March of 2022 as part of the clean desk. Prior to joining True North Lenny received a master’s degree in Energy Systems Engineering from West Virginia University and worked as a petroleum engineer in the oil and gas industry.',
  },
  {
    id: 22,
    firstName: 'Jack',
    lastName: 'McGovern',
    mobile: '9146101592',
    email: 'jsm@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Jack McGovern graduated from SUNY Maritime in 2019 with a B.S. in Marine Transportation. Following graduation, he sailed as a Third Mate on US-flagged vessels in the Gulf of Mexico and on the US West Coast. In March 2024, Jack joined True North Chartering, where he currently works as part of the clean desk.'
  }
]

export default bios
