const bios = [
  {
    id: 1,
    firstName: 'Edward',
    lastName: 'Urquhart',
    title: 'Chief Executive Officer',
    mobile: '2036446458',
    email: 'eau@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Ed a commencé sa carrière chez McQuilling Brokerage Partners Inc. dans le département de nettoyage. Après 3 ans chez Mcquilling, il a rejoint MJLF et Associates où il a géré le département propre. Il est diplômé de l’université de Fordham en 2000, avec un diplôme en finance.'
  },
  {
    id: 2,
    firstName: 'Frank',
    lastName: 'Rendon',
    mobile: '2036446269',
    email: 'fjr@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Après plusieurs années dans le secteur de la finance, Frank a travaillé pendant quatre ans sur les marchés des pétroliers pour les produits pétroliers propres, principalement en Amérique du Sud. Frank est bilingue (espagnol) et est titulaire d’une licence en marketing de l’université de Susquehanna.'
  },
  {
    id: 3,
    firstName: 'William',
    lastName: 'Hirschfeld',
    mobile: '2038072126',
    email: 'wjh@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Bill s’est joint à True North Chartering en 2010 après avoir obtenu un baccalauréat en économie de l’Université Fairfield. Il a passé la plus grande partie de son temps au sein de l’entreprise à travailler au Clean Desk, où il se trouve actuellement, en se concentrant sur le marché LR West.'
  },
  {
    id: 5,
    firstName: 'Brian',
    lastName: 'Boorse',
    mobile: '6103932872',
    email: 'brb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Brian a rejoint True North Chartering en 2015 en tant que courtier en pétroliers se concentrant sur les marchés du pétrole propre. Avant True North, il a travaillé dans le domaine des ventes pour une entreprise de gestion de patrimoine et a obtenu un baccalauréat en commerce et en économie au Ursinus College.'
  },
  {
    id: 6,
    firstName: 'Sean',
    lastName: 'Peterkin',
    mobile: '2039456901',
    email: 'spn@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Sean a obtenu un diplôme en administration des affaires et en transport maritime de SUNY Maritime en 2003. Peu de temps après avoir obtenu son diplôme, Sean a commencé sa carrière en tant que courtier spot, travaillant à la fois en CT et en GVA pour MJLF, ainsi que pour ICAP à Londres. Sean s’est joint à True North en 2012 où il continue de se concentrer sur le marché spot sale.'
  },
  {
    id: 7,
    firstName: 'Brian',
    lastName: 'Vogdes',
    mobile: '2032199751',
    email: 'bjv@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Brian Vogdes travaille actuellement sur les marchés du brut et des PDP. Il se concentre principalement sur les marchés du brut dans les Amériques et en Afrique de l’Ouest. Brian travaille pour True North Chartering depuis son ouverture en 2009. Avant de s’affréter, il a travaillé pour World Fuel Services comme courtier et négociant des bunkers dans les Caraïbes, et a navigué sur le littoral à bord d’un pétrolier battant pavillon américain. Brian est directeur de l’ASBA et a obtenu un baccalauréat en transport maritime de SUNY Maritime en 2004.'
  },
  {
    id: 8,
    firstName: 'Drew',
    lastName: 'Balshi',
    mobile: '2034005200',
    email: 'acb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Drew Balshi a obtenu un baccalauréat en gestion des affaires de l’université Lehigh en 2009. Sa carrière dans le secteur du transport maritime a débuté comme stagiaire en 2007 chez MJLF. Il a travaillé dans une variété de secteurs sales avec True North depuis l’année d’ouverture de l’entreprise en 2009. Drew est le chef du bureau DPP chez True North, couvrant tous les secteurs, des MR aux VLCC. Il aime jouer au basket-ball et au golf, ainsi qu’explorer les innombrables nouveaux restaurants de la ville de New York.'
  },
  {
    id: 9,
    firstName: 'Dwight',
    lastName: 'Tompkins',
    mobile: '9142621628',
    email: 'djt@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Dwight est diplômé de SUNY Maritime en 2007. Après l’obtention de son diplôme, il a navigué sur des bateaux de plaisance battant pavillon américain en tant que second capitaine. En 2009, il est passé à un poste d’exploitation chez General Maritime. Il est ensuite devenu directeur de l’affrètement, couvrant les Panamax, Aframax et Suezmax, avant de se joindre à True North en juin 2018.'
  },
  {
    id: 11,
    firstName: 'Jerry',
    lastName: 'Lichtblau',
    mobile: '2038070699',
    email: 'jnl@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio:
      'Jerry Lichtblau dirige nos efforts de recherche et d’analyse de l’industrie et possède plus de 25 ans d’expérience dans l’industrie maritime. Avant de se joindre à True North, il a été responsable de toutes les recherches et analyses financières chez MJLF & Associates pendant 13 ans. Il a également passé plus de 7 ans chez OSG où il était agent au service des finances. Il a également travaillé pour Sea-Land Corporation, dans le secteur financier. Il est titulaire d’un BS du SUNY Maritime College en Météorologie et Océanographie et d’un MBA de l’Université de Colombie en Finance.'
  },
  {
    id: 13,
    firstName: 'Kaird',
    lastName: 'Durocher',
    mobile: '2034176690',
    email: 'kdr@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio:
      'Kaird a obtenu un baccalauréat en administration des affaires de l’Université Hartwick en 2012 et a rejoint l’équipe de courtage de True North en se concentrant sur le secteur propre. Après plusieurs années, il se joint maintenant à Keith, Noel et Jerry au service des projets. Avant de travailler à temps plein chez True North, Kaird a fait des stages d’été chez True North, ainsi qu’au service juridique de Victaulic, le chef de file mondial des systèmes d’assemblage mécanique de tuyaux.'
  },
  {
    id: 15,
    firstName: 'Christian',
    lastName: 'Uecker',
    mobile: '2034346162',
    email: 'cdu@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio:
      'Christian, chef de notre personnel des opérations, apporte son expérience en tant qu’agent de navires-citernes et gestionnaire des opérations chez les anciens OMI Corp et Torm USA. Christian apporte une vaste expérience des opérations avec les grands pétroliers et les transporteurs de produits. Il est diplômé du SUNY Maritime College avec un diplôme BS en transport maritime.'
  },
  {
    id: 16,
    firstName: 'Edward',
    lastName: 'Micola',
    mobile: '2039215116',
    email: 'evm@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio:
      'Avant d’être courtier, Ed a passé cinq ans en tant qu’officier d’un pétrolier océanique. Au cours des quatre dernières années, il a développé une expertise sur les marchés des pétroliers Suezmax et Aframax. Il est diplômé de SUNY Maritime avec un diplôme en transport maritime.'
  },
  {
    id: 17,
    firstName: 'Edwin',
    lastName: 'Arias',
    mobile: '9148796886',
    email: 'exa@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio:
      'Edwin fait partie de l’équipe des opérations, il était auparavant courtier propre, et il travaille pour l’entreprise depuis 2009. Il est diplômé de l’université de Towson, dans le Maryland, avec un BS en marketing.'
  },
  {
     id: 18,
    firstName: 'Eric',
    lastName: 'Halbeck',
    mobile: '2039124118',
    email: 'erh@truenorthchartering.com',
    emailGroup: 'claims@truenorthchartering.com',
    desk: 'claims',
    bio: ''
  },
  {
    id: 19,
    firstName: 'Mike',
    lastName: 'Hyland',
    mobile: '7328225697',
    email: 'moh@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Mike s’est joint à l’équipe de True North en janvier 2022 et travaille dans les marchés du brut et du pétrole brut synthétique. Avant de se joindre à True North, Mike a passé 15 ans au sein du groupe LITASCO dans le rôle de négociant principal en fret entre NY et Genève. Au sein du groupe Litasco, Mike a affrété des navires pour les bureaux du CPP, du DPP et du brut, et a négocié et négocié des affrètements à temps pour Eiger Shipping S.A.. Avant de rejoindre Litasco en 2007, Mike a travaillé pendant 5 ans comme courtier chez MJLF dans le département spot. Il est diplômé de l’Académie de la marine marchande des États-Unis. Mike aime réparer les bateaux et jouer au golf.'
  },
  {
    id: 20,
    firstName: 'Bobby',
    lastName: 'Beane',
    mobile: '7814240007',
    email: 'rcb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Bobby est diplômé du Massachusetts Maritime en 2019, où il a joué au baseball et obtenu un diplôme en commerce maritime international. Avant de rejoindre le bureau DPP de True North, il a passé des années à travailler tous les jours sur l’eau dans une marina locale. Il aime Led Zeppelin, regarder les équipes sportives de sa ville natale, Boston, et jouer au golf.'
  },
  {
    id: 21,
    firstName: 'Lenny',
    lastName: 'Joutovsky',
    mobile: '5165894525',
    email: 'lmj@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Lenny s’est joint à True North Chartering en mars 2022 en tant que membre du bureau des produits pétroliers propres. Avant de rejoindre True North, Lenny a obtenu une maîtrise en ingénierie des systèmes énergétiques de l’Université de Virginie occidentale et a travaillé comme ingénieur pétrolier dans l’industrie du pétrole et du gaz.'
  },
  {
    id: 22,
    firstName: 'Jack',
    lastName: 'McGovern',
    mobile: '9146101592',
    email: 'jsm@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      ''
  }
];

export default bios;
