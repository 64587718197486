const bios = [
  {
    id: 1,
    firstName: 'Edward',
    lastName: 'Urquhart',
    title: 'Chief Executive Officer',
    mobile: '2036446458',
    email: 'eau@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Ed comenzó su carrera en McQuilling Brokerage Partners Inc. en el departamento de productos petrolíferos limpios. Después de tres años en Mcquilling, se incorporó a MJLF and Associates, donde dirigió el departamento de productos petrolíferos limpios. Se licenció en finanzas por la Universidad de Fordham en el año 2000.'
  },
  {
    id: 2,
    firstName: 'Frank',
    lastName: 'Rendon',
    mobile: '2036446269',
    email: 'fjr@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Tras varios años en el sector de las finanzas, Frank pasó cuatro años trabajando en los mercados de petroleros limpios, principalmente en Sudamérica. Frank es bilingüe (español) y es licenciado en marketing por la Universidad de Susquehanna.'
  },
  {
    id: 3,
    firstName: 'William',
    lastName: 'Hirschfeld',
    mobile: '2038072126',
    email: 'wjh@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Bill se unió a True North Chartering en 2010 tras licenciarse en economía por la Universidad de Fairfield. Ha pasado la mayor parte de este tiempo en la empresa trabajando en la mesa limpia donde actualmente se encuentra, con especial atención en el mercado de LR West.'
  },
  {
    id: 5,
    firstName: 'Brian',
    lastName: 'Boorse',
    mobile: '6103932872',
    email: 'brb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Brian se unió a True North Chartering en 2015 como corredor de buques tanque, concentrándose en los mercados de petróleo limpio. Antes de True North, trabajó en ventas para una empresa de gestión de patrimonios y se graduó en el Ursinus College con una licenciatura en negocios y economía.'
  },
  {
    id: 6,
    firstName: 'Sean',
    lastName: 'Peterkin',
    mobile: '2039456901',
    email: 'spn@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Sean se graduó en SUNY Maritime en 2003 con una licenciatura en administración de empresas y transporte marítimo. Poco después de graduarse, Sean comenzó su carrera como corredor de bolsa, trabajando tanto en CT como en GVA para MJLF, así como para ICAP en Londres. Sean se unió a True North en 2012, donde sigue centrándose en el mercado "sucio" al contado.'
  },
  {
    id: 7,
    firstName: 'Brian',
    lastName: 'Vogdes',
    mobile: '2032199751',
    email: 'bjv@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Brian Vogdes trabaja actualmente en los mercados de Crudo/DPP. Su principal actividad son los mercados de crudo en las Américas y África Occidental. Brian trabaja en True North Chartering desde su apertura en 2009. Antes de dedicarse al fletamento, trabajó para World Fuel Services en la intermediación y el comercio de búnkeres en el Caribe, y navegó por la costa a bordo de un petrolero de bandera estadounidense. Brian es director de ASBA y se graduó en SUNY Maritime con una licenciatura en transporte marítimo en 2004.'
  },
  {
    id: 8,
    firstName: 'Drew',
    lastName: 'Balshi',
    mobile: '2034005200',
    email: 'acb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Drew Balshi se graduó en la Universidad de Lehigh en 2009 con una licenciatura en administración de empresas. Su carrera como transportista comenzó como aprendiz en 2007 en MJLF. Ha trabajado en diversos sectores "sucios" con True North desde su apertura en 2009. Drew es el responsable del DPP Desk en True North, y se ocupa de todos los sectores, desde los MR hasta los VLCC. Le gusta jugar al baloncesto y al golf, así como explorar los infinitos nuevos restaurantes de la ciudad de Nueva York.'
  },
  {
    id: 9,
    firstName: 'Dwight',
    lastName: 'Tompkins',
    mobile: '9142621628',
    email: 'djt@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Dwight se graduó en SUNY Maritime en 2007. Tras su graduación, navegó en ATB, de bandera estadounidense, como segundo oficial. En 2009, pasó a desempeñar un papel de operaciones en General Maritime. Más tarde pasó a ser gerente de fletamento, ocupándose de Panamax, Aframax y Suezmax hasta que se unió a True North en junio de 2018.'
  },
  {
    id: 10,
    firstName: 'Keith',
    lastName: 'Durocher',
    mobile: '2034612042',
    email: 'kmd@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio:
      'Keith tiene más de 40 años de experiencia con una reputación demostrada como anterior director gerente de dos importantes empresas de fletamento y como corredor del mercado al contado con experiencia en los mercados VLCC y SuezMax. También le gusta impartir clases de corretaje para corredores emergentes. Se graduó en la Universidad de Bucknell con una licenciatura en economía y ciencias políticas.'
  },
  {
    id: 11,
    firstName: 'Jerry',
    lastName: 'Lichtblau',
    mobile: '2038070699',
    email: 'jnl@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio:
      'Jerry dirige nuestros esfuerzos de investigación y análisis del sector, y cuenta con más de 25 años de experiencia en la industria marítima. Antes de unirse a True North, estuvo a cargo de toda la investigación y el análisis financiero en MJLF & Associates durante 13 años. También pasó más de 7 años en OSG, donde fue oficial del departamento de finanzas. También ha trabajado para Sea-Land Corporation en el sector financiero. Es licenciado en meteorología y oceanografía por el SUNY Maritime College y tiene un MBA en finanzas por la Universidad de Columbia.'
  },
  {
    id: 12,
    firstName: 'Noel',
    lastName: 'Capellini',
    mobile: '6464790674',
    email: 'npc@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio:
      'Noel tiene más de 25 años de experiencia en la obtención de resultados comerciales junto con una ejecución operativa impecable. Antes de unirse a True North en 2016, Noel trabajó para OSG desde 1990. Allí sus responsabilidades fueron las de gerente de personal marítimo, gerente de operaciones internacionales y gerente de productos internacionales, culminando como responsable adjunto de productos en OSG, donde implementó y supervisó sus iniciativas de crecimiento estratégico interno, pérdidas y ganancias y gestión de cartera. Dirigió con éxito una de las mayores flotas de petroleros de productos del sector, compuesta por más de 50 petroleros, entre LR2, LR1 y MR. Noel se graduó en 1988 en el SUNY Maritime College con un título en transporte marítimo.'
  },
  {
    id: 13,
    firstName: 'Kaird',
    lastName: 'Durocher',
    mobile: '2034176690',
    email: 'kdr@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'projects',
    bio:
      'Kaird se graduó en la Universidad de Hartwick en 2012 con una licenciatura en administración de empresas y se unió al equipo de intermediación de True North, especializándose en el sector limpio. Después de varios años, ahora se une a Keith, Noel y Jerry en el departamento de proyectos. Antes de trabajar a tiempo completo en True North, Kaird pasó los veranos haciendo prácticas en dicha empresa, así como en el departamento jurídico de Victaulic, el líder mundial en sistemas de unión mecánica de tuberías.'
  },
  {
    id: 15,
    firstName: 'Christian',
    lastName: 'Uecker',
    mobile: '2034346162',
    email: 'cdu@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio:
      'Christian, jefe de nuestro personal de operaciones, aporta su experiencia como agente de cisternas y director de operaciones en las antiguas OMI Corp y Torm USA. Christian aporta una amplia experiencia en operaciones con grandes petroleros y buques de transporte de productos. Se graduó en el SUNY Maritime College con una licenciatura en transporte marítimo.'
  },
  {
    id: 16,
    firstName: 'Edward',
    lastName: 'Micola',
    mobile: '2039215116',
    email: 'evm@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio:
      'Antes de dedicarse a la intermediación, Ed pasó cinco años como oficial de un petrolero oceánico. En el transcurso de los últimos cuatro años, ha acumulado experiencia en los mercados de petroleros Suezmax y Aframax. Se graduó en SUNY Maritime con un título en transporte marítimo.'
  },
  {
    id: 17,
    firstName: 'Edwin',
    lastName: 'Arias',
    mobile: '9148796886',
    email: 'exa@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'ops',
    bio:
      'Edwin forma parte del equipo de operaciones. Anteriormente era corredor limpio y lleva en la empresa desde 2009. Se graduó en la Universidad de Towson, en Maryland, con una licenciatura en marketing.'
  },
  {
    id: 18,
    firstName: 'George',
    lastName: 'Verdzadze',
    mobile: '2035613910',
    email: 'gnv@truenorthchartering.com',
    emailGroup: 'operations@truenorthchartering.com',
    desk: 'claims',
    bio:
      'Antes de unirse a True North Chartering, George trabajó para Louis Dreyfus Company como operador de buques durante cuatro años. También pasó unos tres años como oficial de un barco oceánico. George se graduó en la Academia Marítima Estatal de Batumi en Georgia (Rep. de) con un título en navegación y en SUNY Maritime con un título en transporte marítimo.'
  },
  {
    id: 19,
    firstName: 'Mike',
    lastName: 'Hyland',
    mobile: '7328225697',
    email: 'moh@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Mike se unió a True North en enero de 2022 y trabaja en los mercados de Crudo/DPP. Antes de unirse a True North Mike pasó 15 años con el grupo LITASCO en el papel de operador de carga principal entre Nueva York y Ginebra. Mientras estuvo en el grupo Litasco, Mike fletó buques para los departamentos de CPP, DPP y crudo y negoció y comercializó fletamentos por tiempo para Eiger Shipping S.A. Antes de unirse a Litasco en 2007, Mike trabajó durante cinco años como corredor en MJLF, en el departamento de operaciones al contado. Se graduó en la Academia de la marina mercante de los Estados Unidos. A Mike le gusta arreglar barcos y el golf.'
  },
  {
    id: 20,
    firstName: 'Bobby',
    lastName: 'Beane',
    mobile: '7814240007',
    email: 'rcb@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'dpp',
    bio:
      'Bobby se graduó en Massachusetts Maritime en 2019, donde jugó al béisbol y obtuvo un título en negocios marítimos internacionales. Antes de unirse al departamento de DPP en True North, pasó años trabajando todos los días en el agua en un puerto deportivo local. Le gusta Led Zeppelin, ver a los equipos deportivos de su ciudad natal, Boston, y jugar al golf.'
  },
  {
    id: 21,
    firstName: 'Lenny',
    lastName: 'Joutovsky',
    mobile: '5165894525',
    email: 'lmj@truenorthchartering.com',
    emailGroup: 'chartering@truenorthchartering.com',
    desk: 'cpp',
    bio:
      'Lenny se unió a True North Chartering en marzo de 2022 para formar parte de la mesa limpia. Antes de incorporarse a True North, Lenny obtuvo un máster en ingeniería en sistemas energéticos por la Universidad de Virginia Occidental y trabajó como ingeniero petrolero en el sector del petróleo y el gas.'
  }
];

export default bios;
